import React from "react";

function Logo(props) {
  return (
      <svg viewBox="0 0 2248 1228"  {...props} xmlns="http://www.w3.org/2000/svg">
        <path transform="translate(557,78)" d="m0 0 4 1 23 46 11 23 25 50 11 23 29 58 11 23 27 54 11 23 23 46 11 23 29 58 11 23 25 50 11 23 31 62 11 23 23 46 11 23 25 50 11 23 29 58 11 23 29 58 11 23 25 50 11 23 25 50 11 23 25 50 11 23 8 16v2h-303l-22-46-42-84-11-23-30-60-11-23-30-60-11-23-34-68-11-23-26-52-11-23-17-34-6-13h-2l-15 30-11 23-12 25-10 21-19 38-11 23-21 43-11 23-10 21-21 42-11 23-9 19-16 32-14 29-17 35-8 17-19 38-11 23-10 21-3 6h-301v-5l10-19 12-25 11-23 8-16 20-41 17-35 12-25 11-23 8-16 8-17 8-16 9-19 16-32 12-25 11-23 8-16 20-41 17-35 12-25 11-23 8-16 26-53 28-58 10-21 8-16 24-49 11-23 22-45 17-35 19-39 16-33 19-39 8-17 8-16 22-45 14-29 8-17 8-16 12-25 8-16 17-35z"/>
        <path transform="translate(1081)" d="m0 0h9l10 19 11 23 23 46 11 23 23 46 11 23 17 34 11 23 21 42 11 23 23 46 11 23 19 38 11 23 21 42 11 23 21 42 11 23 23 46 11 23 19 38 11 23 21 42 11 23 21 42 11 23 23 46 11 23 19 38 11 23 21 42 11 23 21 42 11 23 23 46 11 23 19 38 11 23 7 14v2h-303l-10-22-35-70-11-23-26-52-11-23-21-42-11-23-16-32-11-23-21-42-11-23-24-48-11-23-19-38-11-23-19-38-11-23-21-42-11-23-21-42-11-23-22-44-11-23-17-34-11-23-20-40-11-23-18-36 1-6 19-40 16-34 13-28 14-30 13-27 15-33 8-16 16-34 12-26 13-27z"/>
        <path transform="translate(1787,903)" d="m0 0h299l5 5 12 25 21 42 11 23 25 50 11 23 21 42 11 23 21 42 11 23 11 22 2 2v3h-303l-17-34-11-23-23-46-11-23-25-50-11-23-24-48-11-23-14-28-10-21-2-5z"/>
      </svg>

  );
}

export default Logo;
